import * as models from "powerbi-models";
import * as pbi from "powerbi-client";

export class PbiPlayground {

  protected visual: any;
  protected pbiService: pbi.service.Service;
  private sectionName: string;
  private shown: boolean;
   

  pageStart(): void {
    console.log("PBI start");

    const embedContainer = $("#embedContainer");

    const divHeight = window.innerHeight - 70;
    embedContainer.height(divHeight);
    console.log(`Set embed <div> height to: ${divHeight}px.`);

    // Get a reference to the embedded report HTML element
    const reportContainer = embedContainer;

    // Read embed application token from Model
    const accessToken = embedContainer.data("embedtoken");
    console.log("Token: " + accessToken);

    // You can embed different reports as per your need by changing the index
    // Read embed URL from Model
    const embedUrl = embedContainer.data("embedurl");
    console.log("Embed URL: " + embedUrl);

    // Read report Id from Model
    const embedReportId = embedContainer.data("reportid");
    console.log("Report ID: " + embedReportId);

    this.sectionName = embedContainer.data("sectionname");
    console.log("Report Page Name: " + this.sectionName);

    // Use the token expiry to regenerate Embed token for seamless end user experience
    // Refer https://aka.ms/RefreshEmbedToken
    const tokenExpiry = "@Model.EmbedToken.Expiration";

    // Get models. models contains enums that can be used.
    //const models = window['powerbi-client'].models;

    // Embed configuration used to describe the what and how to embed.
    // This object is used when calling powerbi.embed.
    // This also includes settings and options such as filters.
    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
    const config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      permissions: models.Permissions.All,
      pageName: this.sectionName,
      visualName: '',
      settings: {
        // Enable this setting to remove gray shoulders from embedded report
        // background: models.BackgroundType.Transparent,
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        commands: [
          {
            exportData: {
              displayOption: models.CommandDisplayOption.Enabled,
            },
            drill: {
              displayOption: models.CommandDisplayOption.Enabled,
            },
            drillthrough: {
              displayOption: models.CommandDisplayOption.Enabled,
            },
            expandCollapse: {
              displayOption: models.CommandDisplayOption.Enabled,
            },
            includeExclude: {
              displayOption: models.CommandDisplayOption.Disabled,
            },
            removeVisual: {
              displayOption: models.CommandDisplayOption.Disabled,
            },
            search: {
              displayOption: models.CommandDisplayOption.Disabled,
            },
            seeData: {
              displayOption: models.CommandDisplayOption.Enabled,
            },
            sort: {
              displayOption: models.CommandDisplayOption.Disabled,
            },
            spotlight: {
              displayOption: models.CommandDisplayOption.Disabled,
            }
          }
        ]
      }
    };

    embedContainer.css("visibility", "hidden");
    console.log("Container hidden");
    // Embed the report and display it within the div container.
    this.visual = window.powerbi.embed(reportContainer[0], config);
    this.visual.on("loaded", () => this.handleVisualLoaded());
    this.visual.on("rendered", () => this.handleVisualRendered()); 
  }

  protected handleVisualLoaded(): void {
    console.log("Loaded visualization");
    this.visual.off("loaded");
    setTimeout(() => {
      console.debug("Timing fallback triggered.  Force show report.");
      this.hideSpinnerShowReport();
    }, 3000);
  }
  protected handleVisualRendered(): void {
    console.log("Rendered visualization");
    this.visual.off("rendered");
    this.hideSpinnerShowReport();
  }

  private hideSpinnerShowReport() {
    if (this.shown) {
      console.debug("Report already shown.");
      return;
    }

    const embedOverlay = $("#embedOverlay");
    embedOverlay.hide();

    const embedContainer = $("#embedContainer");
    embedContainer.css("visibility", "visible");

    this.shown = true;

    this.getActivePageId().then((activePageId: string) => {
      const pageId = this.sectionName;
      console.debug(`Attempt to set active page to ${pageId}.`)
      if (pageId !== activePageId) {
        this.visual.setPage(pageId)
          .then(() => { console.debug(`OK. Set active page to ${pageId}`); });
      } else {
        console.debug(`Report page ${pageId} is already active.`);
      }
    });
  }

  protected getActivePageId(): Promise<string> {
    const id: Promise<string> = this.visual.getPages()
      .then((pages: models.IPage[]): string => {
        for (let i = 0; i < pages.length; i++) {
          if (pages[i].isActive) {
            return pages[i].name;
          }
        }
        return null;
      });
    return id;
  }
}